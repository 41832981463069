import React from 'react';
import { Radio, Form, DatePicker } from 'antd';
import moment from 'moment';
import { connect } from "react-redux";
import { SLOTS, PROVIDERLIST } from '../../../core-units/constants/actionTypes';
import agent from '../../../agent';
import "../home-page.css";
import _ from "lodash";
const dateFormat = 'YYYY-MM-DD';
// const timeFormat = 'hh:mm:ss';

class StepAppointment extends React.Component {
	state = {
		service: {},
		product: {},
		coupon: {},
		total: 0,
		providerId: 1,
		date: localStorage.getItem('date') ? moment(localStorage.getItem('date')) : moment().format(dateFormat),
		time: moment().format('hh:mm:ss'),
		currentDate: new Date(),
		timePro: [],
	}

	componentDidMount() {
		// this.props.providers("",(value)=>{
		// 	const ids = _.chain(_.get(value,'data')).map('id').value();
		// 	this.setState({
		// 		providerId:ids
		// 	})
		// 	localStorage.setItem("providerId", ids)
		// })
		this.getTimeSlots(moment(this.state.date, dateFormat)._i);
		const retrieveService = localStorage.hasOwnProperty('service') ? localStorage.getItem('service') : {};
		const retreveProduct = localStorage.hasOwnProperty('product') ? localStorage.getItem('product') : {};
		this.setState({
			date: localStorage.getItem('date') ? moment(localStorage.getItem('date')) : moment().format(dateFormat),
			service: !_.isEmpty(retrieveService) ? JSON.parse(retrieveService) : {},
			product: !_.isEmpty(retreveProduct) ? JSON.parse(retreveProduct) : {},
			coupon: localStorage.getItem('couponData'),
			total: localStorage.getItem('total'),
			time: localStorage.getItem('time')
		})

	}

	/**Date Disable functions  */
	disabledDate = (current) => {
		// Can not select days before today and today
		return current < moment().endOf('day');
	}

	/**DATES CHANGE FUNCTIONS */
	onChange = (date, dateString) => {
		this.props.setIsSlotAvailable(true)
		if (dateString) {
			this.getTimeSlots(dateString);
		}
		this.setState({
			date: dateString
		})
		localStorage.setItem('date', dateString);
	}

	getTimeSlots = (date) => {
		const providerSlotsMap = {}; // Object to hold time slots for each provider
		this.props.providers("", (value) => {
		  _.map(_.get(value, 'data'), (each) => {
			let requestParams = {
			  service_id: this.state.service.id,
			  date: date,
			  provider_id: _.get(each, 'id')
			};
			this.props.schedules(requestParams, (valu) => {
			  providerSlotsMap[_.get(each, 'id')] = valu; // Store slots for this provider
			  const array = Object.values(providerSlotsMap).flat();
			  const uniqueData = [...new Set(array)];
			  const removeDuplicates = (data, key) => {
				return [
				  ...new Map(data.map(item => [key(item), item])).values()
				];
			  };
			  uniqueData.sort((a, b) => {
				return moment(a.time, 'HH:mm') - moment(b.time, 'HH:mm');
			  });
			  this.setState({
				timePro: removeDuplicates(uniqueData, item => item.time),
				providerSlotsMap // Store providerSlotsMap in state
			  });
			});
		  });
	  
		  const ids = _.chain(_.get(value, 'data')).map('id').value();
		  this.setState({
			providerId: ids
		  });
		  localStorage.setItem("providerId", ids);
		});
	  };
	  
	  /**Time changes */
	  onTimeChange = (e) => {
		this.props.AppvalidationHandler();
		const selectedTime = e.target.value;
		
		// Find the provider whose slot matches the selected time
		const { providerSlotsMap } = this.state;
		let selectedProviderId = null;
		for (const providerId in providerSlotsMap) {
		  const slots = providerSlotsMap[providerId];
		  if (slots.some(slot => slot.time === selectedTime)) {
			selectedProviderId = providerId;
			break;
		  }
		}
	  
		// Update state and local storage
		this.setState({
		  time: selectedTime,
		  selectedProviderId
		});
		localStorage.setItem('time', selectedTime);
		if (selectedProviderId) {
		  localStorage.setItem('selectedProviderId', selectedProviderId);
		}
	  };

	/**getting time slots */
	timeSlots = () => {
		const slots = _.get(this, 'state.timePro') ? _.get(this, 'state.timePro') : _.get(this, 'props.schedule', []);
		if (!_.isEmpty(slots)) {
			return <Radio.Group defaultValue={this.state.time} onChange={this.onTimeChange} buttonStyle="solid">
				{_.map(slots, function (val, key) {
					if (_.get(val, 'time')) {
						return <Radio.Button value={val.time} key={key}>{moment(_.get(val, 'time'), 'hh:mm').format('LT')}</Radio.Button>
					}
				})
				}
			</Radio.Group>
		} else if (!_.isEmpty(_.get(this, 'state.time'))) {
			return <Radio.Button value={moment(_.get(this, 'state.time'), "HH:mm").format('LT')} key={_.get(this, 'state.time')}>{moment(_.get(this, 'state.time'), "hh:mm").format('LT')}</Radio.Button>
		} else if (this.props.schedule.length === 0 && !_.isEmpty(_.get(this, 'state.time'))) {
			return <span>No slots Avaliable </span>
		}
		else {
			return <span>No slots Avaliable </span>
		}
	}

	render() {
		const { service, product,  total, date } = this.state;
		return (
			<div className="InspectionStepsContainer">
				<div className="StepAppointmentInfo">
					<div className="PricingAddOnTitle">
						Book your Appoinment
					</div>
					<div className="InspectionStepTitle">
						{_.get(service, 'name')} <b>$ {_.get(service, 'price')}</b>{_.map(product, function (val, key) {
							return <React.Fragment key={key}>+ {_.get(val, 'name')} <b>$ {_.get(val, 'price')}</b></React.Fragment>
						})}
					</div>
					<div className="CouponCodeTotal">
						<div className="row justify-content-center">
							<div className="col-12 col-lg-2">
								<div className="CouponCodeTotalSection">
									<span>Total</span>
									<span className="CouponCodeTotalAmount">$ {total}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="StepsFormCardContainer">
						<div className="row justify-content-center">
							<div className="col-12 col-md-12 col-lg-6">
								<div className="StepsFormCard">
									<Form className="row">
										<Form.Item className="col-12 col-md-12 mb-2" label="Select Date">
											<DatePicker disabledDate={this.disabledDate} defaultValue={moment(date, dateFormat)} format={dateFormat} onChange={this.onChange} />
										</Form.Item>
										<Form.Item className="col-12 col-md-12 TimeRadioGroup" label="Pick Time">
											{this.timeSlots()}
										</Form.Item>
										{this.props.isSlotAvailable ? '' 
										:
										<div className="col-12 col-md-12 mb-2">
												<span className='slotAvaliableMsg'> Sorry! Your selected slots has already been taken. Please select different Time/Date.</span>
											</div>
										 }
									</Form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => (
	{
		schedule: state.services.slots,
		provider: state.services.providerList
	});

const mapDispatchToProps = dispatch => ({
	schedules: (params, callback) => dispatch({ type: SLOTS, payload: agent.Services.schedule(params), callback: callback }),
	providers: (params, callback) => dispatch({ type: PROVIDERLIST, payload: agent.Services.provider_list(params), callback: callback }),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StepAppointment);