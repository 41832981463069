import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Radio,
  Input,
  Form,
  InputNumber,
} from "antd";

// import au from "./au.png";
// import moment, { normalizeUnits } from "moment";
// import { Scrollbars } from "react-custom-scrollbars";
// import { BrowserRouter as Router, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ADDFIELDS } from "../../../core-units/constants/actionTypes";
import agent from "../../../agent";
import "../home-page.css";
import _ from "lodash";

const { TextArea } = Input;

const StepSellerDetails = (props) => {
  const [form] = Form.useForm();
  const [service, setservice] = useState({});
  const [product, setproduct] = useState({});
  const [coupon, setcoupon] = useState({});
  const [total, settotal] = useState(0);
  // const [providerId, setproviderId] = useState(1);
  const [Rego, setRego] = useState("");
  const [check1, setcheck1] = useState(false);
  const [check2, setcheck2] = useState(false);
  const [feildStatus, setFieldStatus] = useState(false);
  const [client, setClientPhone] = useState("")
  const [allValues, setAllValues] = useState({
    name: "",
    phone: "",
    email: "",
    SellerName: "",
    SellerPhonenumber: "",
    CarLocation: "",
    CarModel: "",
    OdometerReading: "",
    CarIdNumber: "",
    price: "",
    notes: "",
    year:""
  });

  /**Initial API call */

  useEffect(() => {
    async function apiCall() {
      props.fields({ "filter[service_id]": _.get(service, "id") });
      const retrieveService = localStorage.hasOwnProperty("service")
        ? localStorage.getItem("service")
        : {};
      const retreveProduct = localStorage.hasOwnProperty("product")
        ? localStorage.getItem("product")
        : {};
      const retrevevalues = localStorage.hasOwnProperty("allValues")
        ? localStorage.getItem("allValues")
        : {};
      setservice(
        !_.isEmpty(retrieveService) ? JSON.parse(retrieveService) : {}
      );
      setproduct(!_.isEmpty(retreveProduct) ? JSON.parse(retreveProduct) : {});
      setcoupon(localStorage.getItem("couponData"));
      settotal(localStorage.getItem("total"));
      setAllValues(!_.isEmpty(retrevevalues) ? JSON.parse(retrevevalues) : {});
      setRego(localStorage.getItem("Rego"));
      setFieldStatus(JSON.parse(localStorage.getItem("feildStatus")));
      setcheck2(JSON.parse(localStorage.getItem("check1")));
      setcheck1(JSON.parse(localStorage.getItem("check2")));
      form.setFieldsValue(allValues);
    }
    apiCall();
  }, []);

  /**Service and Price details */
  const serviceProductDetails = () => {
    return (
      <React.Fragment>
        <div className="PricingAddOnTitle">
          Your Details, Seller and Car Details
        </div>
        <div className="InspectionStepTitle">
          {_.get(service, "name")} <b>$ {_.get(service, "price")}</b>
          {_.map(product, function (val, key) {
            return (
              <React.Fragment key={key}>
                + {_.get(val, "name")} <b>$ {_.get(val, "price")}</b>
              </React.Fragment>
            );
          })}
        </div>
        <div className="CouponCodeTotal">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-2">
              <div className="CouponCodeTotalSection">
                <span>Total</span>
                <span className="CouponCodeTotalAmount">$ {total}</span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  /**Form values changes */
  const onFormLayoutChange = (values, e) => {
    setAllValues({ ...allValues, ...e, ...("+61" + allValues.phone) });
    localStorage.setItem("allValues", JSON.stringify(e));

    //--changed by Alobin Technologies--
    /*commented and replaced this line
    _.isNumber(e.OdometerReading && e.SellerPhonenumber && e.phone) &&
    filter.test(e.email)
      removed && e.SellerPhonenumber && e.phone
    */
    var filter = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (
      !_.isEmpty(
        e.CarModel && e.CarIdNumber && e.SellerName && e.email && e.name
      ) &&
      _.isNumber(e.OdometerReading) &&
      filter.test(e.email)
    ) {
      props.formvalidateHandler(true);
      setFieldStatus(true);
      localStorage.setItem("feildStatus", true);
    } else {
      props.formvalidateHandler(false);
      setFieldStatus(false);
      localStorage.setItem("feildStatus", false);
    }
  };

  /**Radio group changes */
  const carNoChange = (e) => {
    props.regoValidation(e.target.value);
    setRego(e.target.value);
    localStorage.setItem("Rego", e.target.value);
  };

  /**Terms check */
  const checkChange1 = (e) => {
    props.termsValidationHandler(e.target.checked);
    setcheck1(e.target.checked);
    localStorage.setItem("check1", e.target.checked);
  };

  const checkChange2 = (e) => {
    props.checkValidationHandler(e.target.checked);
    setcheck2(e.target.checked);
    localStorage.setItem("check2", e.target.checked);
  };


  /**Form */
  const forminfo = () => {
    const retrevevalues = localStorage.hasOwnProperty("allValues")
      ? localStorage.getItem("allValues")
      : {};
    const values = !_.isEmpty(retrevevalues) ? JSON.parse(retrevevalues) : {};
    return (
      <Form
        className="row"
        initialValues={values}
        onValuesChange={onFormLayoutChange}
      >
        <Form.Item
          className="col-12 col-lg-8"
          label="Your Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your username!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-8"
          label="Your E-mail Address"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-8 phone__number--preImg"
          label="Your Phone Number"
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input your phone!",
            },
          ]}
        >
          {/* --changed by Alobin Technologies-- */}
          <Input
          style={{border:"none",borderStyle:"none"}}
            prefix={"+61"}
            onChange={(e) => {
              setClientPhone("+61"+ e.target.value);
            }}
            defaultValue={client}
          />

          {/*<InputNumber}
            controls={false}
            prefix={
              <>
                <img
                  alt="flag"
                  style={{ width: 18, height: 18, verticalAlign: "sub" }}
                  src={au}
                />
                <span>+61</span>
              </>
            }
          />*/}
        </Form.Item>
        <Form.Item className="col-12">
          <hr />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-8"
          label="Seller Name"
          name="SellerName"
          rules={[
            {
              required: true,
              message: "Please input your Seller Name!",
            },
          ]}
        >
          <Input defaultValue={allValues.SellerName} />
        </Form.Item>

        <Form.Item
          className="col-12 col-lg-8"
          label="Seller Phone Number"
          name="SellerPhonenumber"
          rules={[
            {
              required: true,
              message: "Please input your Seller Phone Number!",
            },
          ]}
        >
          {/*--changed by Alobin Technologies*/}

          <Input defaultValue={allValues.SellerPhonenumber} />
          {/* <InputNumber
            controls={false}
            prefix={
              <>
                <img
                  alt='flag'
                  style={{ width: 18, height: 18, verticalAlign: 'sub' }}
                  src={au}
                />
                <span>'+61'</span>
              </>
            }
           
          />*/}
        </Form.Item>

        <Form.Item className="col-12">
          <hr />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-8"
          label="Car Make and Model"
          name="CarModel"
          rules={[
            {
              required: true,
              message: "Please input your Car Model!",
            },
          ]}
        >
          <Input placeholder="Hyundai i20" defaultValue={allValues.CarModel} />
        </Form.Item>
        
        <Form.Item
          className="col-12 col-lg-8"
          label="Car Manufactured Year"
          name="year"
          rules={[
            {
              required: false,
              message: "Please input your Manufactured Year!",
            },
          ]}
        >
          <Input placeholder="2022" defaultValue={allValues.year} />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-8"
          label="Car Odometer Reading"
          name="OdometerReading"
          rules={[
            {
              required: true,
              message: "Please enter Car Odometer Reading!",
            },
          ]}
        >
          <InputNumber
            placeholder="130,000"
            defaultValue={allValues.OdometerReading}
          />
        </Form.Item>
        <Form.Item
          className="col-12"
          rules={[{ required: true, message: "Please pick an item!" }]}
        >
          <Radio.Group
            className="SellerDetailsRadioGroup"
            name="radiogroup"
            value={Rego}
            onChange={carNoChange}
          >
            <Radio value="Rego">Car Rego Number</Radio>
            <Radio value="VIN">Car VIN Number</Radio>
            <Radio value="Chasis Number">Car Chasis Number</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-8"
          name="CarIdNumber"
          rules={[
            {
              required: true,
              message: "Please input your Car Id Number!",
            },
          ]}
        >
          <Input
            placeholder="E.g. 123"
            defaultValue={allValues.CarIdNumber}
            required="required"
          />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-8"
          label="Notes"
          name="notes"
          rules={[
            {
              required: false,
              message: "Please input your notes!",
            },
          ]}
        >
          <TextArea rows={3} defaultValue={allValues.notes} />
        </Form.Item>
        <Form.Item className="col-12 col-lg-8">
          <div className="AcceptTermsCheckBoxSet">
            <Checkbox onChange={checkChange1} checked={check1} />
            <div className="AcceptTermsCheckBoxText">
              I agree to the{" "}
              <a className='footer-terms-conditions' href='https://www.safe-buy.com.au/read-the-terms-conditions-of-prepurchase-vehicle-services/' target='_blank'>
                Terms and Conditions
              </a>
            </div>
          </div>
          <div className="AcceptTermsCheckBoxSet">
            <Checkbox onChange={checkChange2} checked={check2} />
            <div className="AcceptTermsCheckBoxText">
              I agree to{" "}
              <a href="https://safebuy.com.au/booking-refund-and-cancellation-policy/" target="_blank">
                Service, Booking and Refund Policy
              </a>
            </div>
          </div>
        </Form.Item>
      </Form>
    );
  };

  const render = () => {
    return (
      <div className="StepSellerDetailsStepsContainer">
        <div className="StepAppointmentInfo">
          {serviceProductDetails()}
          <div className="StepsFormCardContainer">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-7">
                <div className="StepsFormCard">{forminfo()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      // </Scrollbars>
    );
  };
  return render();
};
const mapStateToProps = (state) => ({
  addfield: state.booking.additionalfields,
});

const mapDispatchToProps = (dispatch) => ({
  fields: (params, callback) =>
    dispatch({
      type: ADDFIELDS,
      payload: agent.Bookings.addition_feilds(params),
      callback: callback,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(StepSellerDetails);
