
import {
  ASYNC_START,
  ASYNC_END,
  LOGIN,
  // LOGOUT,
  // // REGISTER,
  // IS_LOGGEDIN,
  // SIGNUP,
  // EMPLOYER_SIGNUP
} from "../constants/actionTypes";
import { notification } from "antd";
import _ from 'lodash';

const promiseMiddleware = store => next => action => {
  if (isPromise(action.payload)) {
    store.dispatch({ type: ASYNC_START, subtype: action.type });

    const currentView = store.getState().viewChangeCounter;
    const skipTracking = action.skipTracking;

    action.payload.then(
      res => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        action.payload = res;
        store.dispatch({ type: ASYNC_END, promise: action.payload });
        store.dispatch(action);   
        if(_.has(action, 'callback')){
          if(_.isFunction(_.get(action, 'callback'))){
            var callback = _.get(action, 'callback');
            callback(action.payload);
          }
        }
        if(_.has(action, 'generalCallback')){
          if(_.isFunction(_.get(action, 'generalCallback'))){
            var callback = _.get(action, 'generalCallback');
            callback(action.payload, true);
          }
        }
        if(_.has(action,'successNotification') && !_.get(action, 'successNotification')){
          return;
        }     
        if (
          action.payload &&
          action.payload.data &&
          action.payload.data.success
        ) {

          if (action.payload.data.success && !action.payload.data.isLoggedIn) {
            notification.success({
              message: "Success",
              description: action.payload.data.message
            });
          }
        } else if (action.payload  && action.payload.message && (action.payload.type==='education' || action.payload.type==='experience' || action.payload.type==='final')){
          notification.success({
            message: "Success",
            description: action.payload.message,
          
          });

        } else if (action.payload.details && action.payload.showMessage){          
          notification.success({

            message: "Success",
            description: action.payload.message,
          
          });
        }
        else if (action.payload.details && action.payload.success){          
          notification.success({

            message: "Success",
            description: action.payload.message,
          });
        }

      },
      error => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        action.error = true;
        
        
        action.payload =
          error.response && error.response.body ? error.response.body : {};
        if (!action.skipTracking) {
          store.dispatch({ type: ASYNC_END, promise: action.payload });
        }
        store.dispatch(action);        
        if(_.has(action, 'generalCallback')){
          if(_.isFunction(_.get(action, 'generalCallback'))){
            var callback = _.get(action, 'generalCallback');
            callback(action.payload, false);
          }
        }

        if (
          action &&
          action.payload &&
          action.payload.error &&
          action.payload.error.data &&
          action.payload.error.data.length > 0  
        ) {
          action.payload.error.data.map((value, key) => {
            // value.rules.length > 0 && value.rules.map((err, index) => {
              notification.error({
                message: "Error",
                description: value
              // });
            });
          });
        } else if (action && action.payload && action.payload.error &&  Object.keys(action.payload.error.data).length>0){
          // action.payload.error.errors.map((value,key) => {
            // value.rules.length > 0 && value.rules.map((err, index)=>{
              !_.isEmpty(_.get(action, 'payload.error.data'))&&Object.keys(action.payload.error.data).map(function(key, index) {
               const error= action.payload.error.data[key];
               if(!error[0].errors) {
                notification.error({
                  message: "Error",
                  description: error[0] 
                });
               }
               else{
                error.map(function(val, index) {
                  notification.error({
                    message: "Error",
                    description: `${val.field}: ${val.errors[0] }`
                  });
                });
               }
               
              });
              
            // })
          // })
        }
        else if( _.get(action, 'payload.error.message', false)){
          notification.error({
            message: "Error",
            description: _.get(action, 'payload.error.message','payload.error.data[0]')
          });
        }else if(_.has(action,'showErrNotification') && _.get(action, 'showErrNotification') && _.get(action, 'payload.error.message', false)){
          notification.error({
            message: "Error",
            description: _.get(action, 'payload.error.message')
          });
        }
      }
    );

    return;
  }

  next(action);
};

const localStorageMiddleware = store => next => action => {
  if (
    action.type === LOGIN 
    // action.type === IS_LOGGEDIN
  ) {
    if (!action.error) {
      window.localStorage.setItem(
        "safebuyLoggedInStatus",
        action.payload.isLoggedIn
      );
      // agent.setToken(action.payload.isLoggedIn);
    }
  } 
  // else if (action.type === LOGOUT) {
  //   window.localStorage.setItem("safebuyLoggedInStatus", "");
  //   // agent.setToken(null);
  // }

  next(action);
};

function isPromise(v) {
  return v && typeof v.then === "function";
}

export { promiseMiddleware, localStorageMiddleware };
