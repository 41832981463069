import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import { GlobalVariable } from "../core-units/config/generalConfig";

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = GlobalVariable.MIDDLEWARE_URL;

const responseBody = res => res.body;

const isObject = obj => {
  return Object(obj) === obj;
};

const customHeaders = (req, headers) => {
  if (isObject(headers)) {
    for (let [key, value] of Object.entries(headers)) {
      req.header[key] = value;
    }
  }
  return req;
};
const additionalQueryParams = {};

const requests = {
  del: (url, params) =>
    superagent
      .del(`${API_ROOT}${url}`)
      .query(params)
      .withCredentials()
      .then(responseBody),
  get: (url, params = {}, headers = {}) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .query({ ...additionalQueryParams, ...params })
      .use(req => {
        customHeaders(req, headers);
      })
      .withCredentials()
      .then(responseBody),
  put: (url, body, urlParams = {}) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .query(urlParams)
      .withCredentials()
      .then(responseBody),
  post: (url, body, params = {}) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .query({ ...additionalQueryParams, ...params })
      .withCredentials()
      .then(responseBody),
  formPost: (url, body, params = {}) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .query(params)
      .set("Content-Type", "multipart/form-data")
      .withCredentials()
      .then(responseBody)
};

const Auth = {
  login: (requestBody) => requests.post("/auth", requestBody),
  google_location: (requestBody) => requests.get("/distance", requestBody)
  
};
const Services = {
  list: (requestBody) => requests.get('/list', requestBody),
  products: (requestBody) => requests.get('/services/products', requestBody),
  view: (requestBody) => requests.get('/services', requestBody),
  promo_code: (requestParams, requestBody) => requests.post('/apply-promo-code',requestParams, requestBody),
  schedule:(requestBody) => requests.get('/schedule/available-slots', requestBody),
  clients:(requestBody) => requests.post('/clients', requestBody),
  provider_list: (requestBody) => requests.get('/providers ', requestBody),
  products_view: (requestBody) => requests.get('/products', requestBody),

}
const Bookings = {
  new_booking:(requestBody) => requests.post('/bookings ', requestBody),
  addition_feilds:(requestBody) => requests.get('/additional-fields  ', requestBody),
  order_list:(requestBody) => requests.get('/invoices', requestBody),
  payment:(requestParams, requestBody) => requests.post('/accept-payment', requestParams, requestBody),
  delete:(requestBody) => requests.del('/bookings/id', requestBody),
  bookDetails:(requestBody) => requests.get('/details-bookings', requestBody),
  update_booking:(requestBody) => requests.post('/update-bookings', requestBody),
  stripe_charges:(requestBody) => requests.post('/stripe/charges', requestBody),


}
const ClientBooking = {
  token:(requestBody) => requests.post('/ ', requestBody),
  booking:(requestBody) => requests.post('/book ', requestBody),

}
export default {
  Auth,
  Services,
  Bookings,
  ClientBooking,
};
