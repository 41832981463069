
import React from 'react'
import { Route, Switch } from 'react-router';
import PageHeader from "../components/Header/index";
import PageFooter from "../components/Footer/index";

import * as routeConfig from "../config/routeConfig";
import HomePageComponent from '../../containers/home-page';
import TermsConditions from '../../containers/terms-conditions/terms-conditions';
import RefundPolicy from '../../containers/terms-conditions/refund-policy';
import PrivacyPolicy from '../../containers/terms-conditions/privacy-policy';

// const isAuth =
//   localStorage.getItem("safebuyLoggedInStatus") !== ""
//     ? JSON.parse(localStorage.getItem("safebuyLoggedInStatus"))
//     : false;

const routes = (
  <div>
     <PageHeader />
    <Switch>
      <Route exact path="/" component={HomePageComponent} />
      <Route exact path={routeConfig.globalRoutes.terms} component={TermsConditions} />
      <Route exact path={routeConfig.globalRoutes.refund} component={RefundPolicy} />    
      <Route exact path={routeConfig.globalRoutes.privacy} component={PrivacyPolicy} />    
    </Switch>
    <PageFooter />
  </div>
);

export default routes