import React from 'react';
import { Checkbox, Radio, Empty, InputNumber, Spin } from 'antd';
import { connect } from "react-redux";
import { LOGIN, SERVICELIST, PRODUCTLIST, COUPON, SERVICEVIEW, PRODUCTVIEW } from '../../../core-units/constants/actionTypes';
import agent from '../../../agent';
import "../home-page.css";
import _ from "lodash";

class StepInspection extends React.Component {

	state = {
		value: 1,
		diffLoc: 0,
		data: {},
		service: {},
		product: {},
		total: 0,
		price: 0,
		loading: false,
		showAddons: false,
		name: ''
	};

	componentDidMount() {
		const retrieveService = localStorage.hasOwnProperty('service') ? localStorage.getItem('service') : {};
		const retreveProduct = localStorage.hasOwnProperty('product') ? localStorage.getItem('product') : {};
		if (this.state.diffLoc !== localStorage.getItem('diffLoc')) {
			this.setState({
				diffLoc: localStorage.hasOwnProperty('service') ? localStorage.getItem('diffLoc') : 0,
				value: _.parseInt(localStorage.getItem('value')),
				price: localStorage.getItem('price'),
				total: !_.isEmpty(localStorage.getItem('total')) ? localStorage.getItem('total') : 0,
				service: !_.isEmpty(retrieveService) ? JSON.parse(retrieveService) : {},
				product: !_.isEmpty(retreveProduct) ? JSON.parse(retreveProduct) : [],
				name: localStorage.getItem('selectedValuelocation'),
				showAddons: false
			})
		}
		// if (localStorage.getItem('product')) {
		// 	this.props.validationHandler();
		// }
		// if (localStorage.getItem('product') == null) {
		// 	this.props.askingPriceValidations(JSON.parse(localStorage.getItem("product"))); //alobin tech
		// }
	}

	componentDidUpdate(prevState) {
		if (this.state.diffLoc !== localStorage.getItem('diffLoc')) {
			this.setState({
				diffLoc: localStorage.getItem('diffLoc'),
				product: [],
				total: !_.isEmpty(localStorage.getItem('total')) ? localStorage.getItem('total') : 0,
				price: 0,
				showAddons: false
			})

		}
		if (localStorage.getItem('product')) {
			this.props.validationHandler();
		}
		this.props.askingPriceValidations(localStorage.getItem("product")); //alobin tech
		if (localStorage.getItem("product") == null) { localStorage.removeItem('price') }
		// this.state.diffLoc !== localStorage.getItem('diffLoc') ? window.location.reload() : window.location.reload()
	}

	/**Service onchange fuction */
	onChange = async e => {
		if (!_.isEmpty(localStorage.getItem('selectedValuelocation'))) {
			this.setState({
				loading: true,
				showAddons: false
			})
			this.props.validationHandler();
			window.scrollTo({
				top: document.body.scrollHeight,
				left: 0,
				behavior: 'smooth'
			})
			let queryParams = {
				'filter[service_id]': e.target.value
			}
			this.props.servicesView({ id: e.target.value }, (value) => {
				this.setState({
					value: e.target.value,
					service: value,
					total: value.price
				});
				localStorage.setItem('service', JSON.stringify(value));
				localStorage.setItem('total', _.get(value, 'price'));
			});
			await this.props.productsList(queryParams, () => {
				this.setState({
					loading: false,
					showAddons: true
				})
			});
			localStorage.setItem('value', e.target.value);
		} else {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			})
		}
	};

	/**SERVICE LIST  */
	servicesData = () => {
		const { value } = this.state;
		const service = _.get(this, 'props.services.data', [])
		// console.log("services",service);
		return (
			<div className="PricingCardSection" id='sample'>
				<Radio.Group onChange={this.onChange} value={value}>
					<div className="row justify-content-center">
						{(() => {
							if (!_.isEmpty(service) && _.isEmpty(localStorage.getItem('diffLoc'))) {
								const data = service.slice(0, 3)
								return _.map(data, function (val, key) {
									return (
										<div className="col-9 col-md-4 col-lg-3" key={key}>
											<div className="PricingCard">
												<Radio value={val.id}>
													<span className="PricingCardInfo">
														<span className="PricingCardAmount">$ {_.get(val, 'price', '-')}</span>
														<span className="PricingCardPlan">{_.get(val, 'name', '-')}</span>
														{/* <span className="PricingCardDesc">Car Inspection</span> */}
													</span>
												</Radio>
											</div>
										</div>
									);
								})
							} else if (!_.isEmpty(service) && parseFloat(localStorage.getItem('diffLoc')) <= 50) {
								const data = service.slice(0, 3)
								return _.map(data, function (val, key) {
									return (
										<div className="col-9 col-md-4 col-lg-3" key={key}>
											<div className="PricingCard">
												<Radio value={val.id}>
													<span className="PricingCardInfo">
														<span className="PricingCardAmount">$ {_.get(val, 'price', '-')}</span>
														<span className="PricingCardPlan">{_.get(val, 'name', '-')}</span>
														{/* <span className="PricingCardDesc">Car Inspection</span> */}
													</span>
												</Radio>
											</div>
										</div>
									);
								})
							} else if (!_.isEmpty(service) && parseFloat(localStorage.getItem('diffLoc')) > 50 && parseFloat(_.get(this, 'state.diffLoc')) <= 60) {
								const data = service.slice(1, 3);
								return _.map(data, function (val, key) {
									return (
										<div className="col-9 col-md-4 col-lg-3" key={key}>
											<div className="PricingCard">
												<Radio value={val.id}>
													<span className="PricingCardInfo">
														<span className="PricingCardAmount">$ {_.get(val, 'price', '-')}</span>
														<span className="PricingCardPlan">{_.get(val, 'name', '-')}</span>
														{/* <span className="PricingCardDesc">Car Inspection</span> */}
													</span>
												</Radio>
											</div>
										</div>
									);
								})
							} else if (!_.isEmpty(service) && parseFloat(localStorage.getItem('diffLoc')) > 60 && parseFloat(_.get(this, 'state.diffLoc')) <= 90) {
								// const data = service.slice(3, 5);
								const data = service.slice(4, 6);
								return _.map(data, function (val, key) {
									return (
										<div className="col-9 col-md-4 col-lg-3" key={key}>
											<div className="PricingCard">
												<Radio value={val.id}>
													<span className="PricingCardInfo">
														<span className="PricingCardAmount">$ {_.get(val, 'price', '-')}</span>
														<span className="PricingCardPlan">{_.get(val, 'name', '-')}</span>
														{/* <span className="PricingCardDesc">Car Inspection</span> */}
													</span>
												</Radio>
											</div>
										</div>
									);
								})

							} else if (!_.isEmpty(service) && parseFloat(localStorage.getItem('diffLoc')) > 90 && parseFloat(_.get(this, 'state.diffLoc')) <= 120) {
								// const data = service.slice(5, 6);
								const data = service.slice(6, 7);
								return _.map(data, function (val, key) {
									return (
										<div className="col-9 col-md-4 col-lg-3" key={key}>
											<div className="PricingCard">
												<Radio value={val.id}>
													<span className="PricingCardInfo">
														<span className="PricingCardAmount">$ {_.get(val, 'price', '-')}</span>
														<span className="PricingCardPlan">{_.get(val, 'name', '-')}</span>
														{/* <span className="PricingCardDesc">Car Inspection</span> */}
													</span>
												</Radio>
											</div>
										</div>
									);
								})

							} else if (!_.isEmpty(service) && parseFloat(localStorage.getItem('diffLoc')) > 120) {
								return <Empty description={<strong>There is no inspection available in the location you have entered.</strong>} />
							}
							else if (_.isEmpty(service) && !_.isEmpty(this, 'state.service') && Object.keys(this.state.service).length > 0) {
								// return _.map(data, function (val, key) {
								return (
									<div className="col-9 col-md-4 col-lg-3" key={this.state.service}>
										<div className="PricingCard">
											<Radio value={_.get(this, 'state.service.id')}>
												<span className="PricingCardInfo">
													<span className="PricingCardAmount">$ {_.get(this, 'state.service.price')}</span>
													<span className="PricingCardPlan">{_.get(this, 'state.service.name')}</span>
													{/* <span className="PricingCardDesc">Car Inspection</span> */}
												</span>
											</Radio>
										</div>
									</div>
								);
								//})
							}
						})()}
					</div>
				</Radio.Group>
			</div>
		)
	}

	/**PRODUCT CHANGE FUNCTION  */
	productchange = (val) => {
		let products = [];
		let currentThis = this;
		if (val.length === 0 && this.state.product) {
			this.setState({
				total: this.state.service.price,
				product: val
			})
			localStorage.setItem('product', val);
			localStorage.setItem('total', this.state.service.price);

		} else {
			_.map(val, function (each, key) {
				let urlParams = {
					id: each
				}
				currentThis.props.productsView(urlParams, (value) => {
					products.push(value);
					const addonPrice = _.sumBy(products, 'price');
					localStorage.setItem('product', JSON.stringify(products));
					currentThis.setState({
						product: products,
						total: currentThis.state.service.price + addonPrice
					})
					localStorage.setItem('total', currentThis.state.service.price + addonPrice);
				});

			})
		}
		// if (localStorage.getItem('product')) {
		// 	if (this.props.prodId.includes(4)) {
		// 		if (this.props.askingPriceValidation == true) {
		// 			this.props.setAskingPriceValidation(false)
		// 		} else {
		// 			this.props.setAskingPriceValidation(true)
		// 		}
		// 	}
		// }

		// const addonPrice = _.sumBy(val, 'price');
		// localStorage.setItem('product', JSON.stringify(val));
		// this.setState({
		// 	product: val,
		// 	total: this.state.service.price + addonPrice
		// })
		// localStorage.setItem('total', this.state.service.price + addonPrice);
	}

	/**PRICE CALUCATION	  */
	totalPrice = () => {
		return (
			<div className="CouponCodeTotalSection">
				<span>Total</span>
				<span className="CouponCodeTotalAmount">{`$ ${_.get(this, 'state.total', '-')}`}</span>
			</div>
		)
	}

	/**ADDONS LIST */
	productList = () => {
		const retreveProduct = _.isEmpty(localStorage.getItem('product')) ? [] : localStorage.getItem('product');
		const products = _.get(this, 'props.products.data', this.state.product);
		if (this.state.loading === true) {
			return (
				<div className="example">
					<Spin />
				</div>
			)
		} else {
			return (
				<div className="PricingCardSection">
					<Checkbox.Group onChange={this.productchange} defaultValue={_.chain(this.state.product).map('id').value()}>
						<div className="row justify-content-center">
							{
								_.map(products, function (val, key) {
									return (
										<div className="col-9 col-md-4 col-lg-3" key={key}>
											<div className="PricingCard" >
												{/* --changed by Alobin Technologies-- */}
												{/* Highlighted for card valuation by using active class { className={_.get(val, 'product.name', val.name) === "Car Valuation Report" ? 'ant-card-wrapper':''}*/}
												<Checkbox value={_.get(val, 'product.id', val.id)} className={_.get(val, 'product.name', val.name) === "Car Valuation Report" ? 'ant-card-wrapper' :
													_.get(val, 'product.name', val.name) === 'Car History Report (PPSR Included)' ? 'ant-card-wrapper-car-history' : ''}>
													{/* {console.log("val",val,"val.name",val.name)} */}
													<span className="PricingCardInfo">
														<span className="PricingCardAmount">$ {_.get(val, 'product.price', val.price)}</span>
														<span className="PricingCardPlan">{_.get(val, 'product.name', val.name)}</span>
													</span>
												</Checkbox>
											</div>
										</div>
									)
								})
							}
						</div>
					</Checkbox.Group>
				</div>
			)
		}

	}

	/**ADDONS LABELING */
	addonsData = () => {
		const { service, diffLoc } = this.state;
		if (!_.isEmpty(service)) {
			return (
				<React.Fragment>
					<div className="PricingAddOnTitle">
						Add Ons
					</div>
					<div className="PricingAddOnSubTitle">
						{_.get(service, 'name')}<b> $ {_.get(service, 'price')}</b>
					</div>
					<div className="PricingAddOnDesc">
						SafeBuy recommend below add ons for more peace of mind
					</div>
				</React.Fragment>
			)
		}
	}


	/**PRICE CHANGE */
	onPriceChange = (value) => {
		this.setState({
			price: value
		})
		localStorage.setItem('price', value)
	}
	priceValidation = () => {
		// console.log('this',this);
		const { product, price } = this.state;
		return _.map(product, (value, key) => {
			if (value.id === 4) {
				return (
					<div className="row justify-content-center" style={{ width: 100 + '%', float: 'left' }} >
						<div className="col-12 col-md-5 col-lg-5">
							<div className="PricingAddOnDesc font-weight-bold">
								Please enter vehicle asking price by seller
							</div>
							<div className="CouponCodeInputSection">
								<div className="row justify-content-center">
									<div className="col-12 col-md-10 col-lg-4">
										<InputNumber
											defaultValue={price}
											min={1}
											formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
											parser={value => value.replace(/\$\s?|(,*)/g, '')}
											onChange={this.onPriceChange}
											className="InputNumberPrice w-100"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				)
			}
		})
	}


	render() {
		const { service, coupon, diffLoc, product, price } = this.state;

		if (!_.isEmpty(diffLoc)) {
			return (
				<div className="InspectionStepsContainer" >
					<div className="InspectionStepTitle">
						Standard Inspection is available up to 50 Kms. Comprehensive and Premium price increases after 60 Kms.
					</div>
					{this.servicesData()}
					{parseFloat(localStorage.getItem('diffLoc')) < 160 && (
						<div className="PricingAddOnSection">
							{this.state.showAddons && this.addonsData()}
							{this.state.showAddons && this.productList()}
							{this.state.showAddons && this.priceValidation()}

							<div className="row justify-content-center w-100">
								<div className="col-12 col-md-5 col-lg-5 text-center">
									<div className="CouponCodeTotal">
										{this.totalPrice()}
									</div>
								</div>
							</div>
						</div>
					)}

				</div>
			);
		} else {
			return (
				<div className="InspectionStepsContainer">
					<div className="InspectionStepTitle">
						Standard Inspection is available up to 50 Kms. Comprehensive and Premium price increases after 60 Kms.
					</div>
					{this.servicesData()}
				</div>
			)
		}

	}
}

const mapStateToProps = state => (
	{
		services: state.services.serviceList,
		products: state.services.productList,
		promoCode: state.services.couponlist,
		serviceView: state.services.serviceView,
		productView: state.services.productView,
	});

const mapDispatchToProps = dispatch => ({
	auth: params => dispatch({ type: LOGIN, payload: agent.Auth.login(params), successNotification: false }),
	servicesList: (params, callback) => dispatch({ type: SERVICELIST, payload: agent.Services.list(params), callback: callback }),
	productsList: (params, callback) => dispatch({ type: PRODUCTLIST, payload: agent.Services.products(params), callback: callback }),
	productsView: (params, callback) => dispatch({ type: PRODUCTVIEW, payload: agent.Services.products_view(params), callback: callback }),
	servicesView: (params, callback) => dispatch({ type: SERVICEVIEW, payload: agent.Services.view(params), callback: callback }),
	coupons: (params, urlParams, callback) => dispatch({ type: COUPON, payload: agent.Services.promo_code(params, urlParams), callback: callback }),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StepInspection);

