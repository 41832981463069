import React, { useState, useEffect } from 'react';
import { Button, notification, Result, Input, Typography } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import agent from '../../../agent';
import Logo from '../../../assets/img/payment_logo.png';
import {
  CLIENTTOKEN,
  CLIENTBOOKING,
  ORDERLIST,
  PAYMENTS,
  BOOKDETAILS,
  DELETEBOOKING,
  COUPON,
  STRIPECHARGES,
  UPDATEBOOKING,
} from '../../../core-units/constants/actionTypes';
import StripeCheckout from 'react-stripe-checkout';
import { GlobalVariable } from '../../../core-units/config/generalConfig';
import '../home-page.css';
import _ from 'lodash';
const { Text, Link } = Typography;

const StepPayment = props => {
  const [total, settotal] = useState(0);
  const [orderId, setOrderId] = useState(0);
  const [coupon, setCoupon] = useState('');
  const [loading, setLoading] = useState(false);
  const [bookingData, setBookingData] = useState({});

  useEffect(() => {
    async function apiCall() {
      settotal(_.multiply(localStorage.getItem('total'), 100));
      setOrderId(localStorage.getItem('order_id'));
    }

    apiCall();
  }, []);

  /**creating booking */
  const bookingClass = async () => {
    const addfield = _.get(props, 'addfield.data', []);
    const additional_fields = {};
    const retrevevalues = localStorage.hasOwnProperty('allValues')
      ? localStorage.getItem('allValues')
      : {};
    const timeStramp = localStorage.getItem('time');
    const allValues = !_.isEmpty(retrevevalues)
      ? JSON.parse(retrevevalues)
      : {};
    const clientData = {
      name: allValues.name,
      email: allValues.email,
      phone:`+61${allValues.phone}`,
    };
    if (!_.isEmpty(addfield)) {
      return _.map(addfield, function (val, key) {
        switch (val.name) {
          case '174f93dea26a3e68edd5863971f1a776':
            additional_fields[val.name] = allValues.SellerName;
            break;
          case '1e03f7a8574a6acea8d9871c3fb65b25':
            //to remove 0 from Sellerphonenumber
            // additional_fields[val.name] = `0${allValues.SellerPhonenumber}`;
            additional_fields[val.name] = allValues.SellerPhonenumber;
            break;
          case '2d42cf2bb70e82898ba9ffc46dba74f2':
            additional_fields[val.name] = localStorage.getItem(
              'selectedValuelocation'
            );
            break;
          case '9f785f73333f13a44dbd5cc9df0060f4':
            additional_fields[val.name] = allValues.CarModel;
            break;
          case 'ac59cc4cfbdefb7805668ea679018e02':
            additional_fields[val.name] = allValues.OdometerReading;
            break;
          case '661e7ff7914016277b3e4de4c62fb285':
            additional_fields[val.name] = allValues.CarIdNumber;
            break;
          case 'a25f4d5f4cba4c7428d27430d7dca0d6':
            additional_fields[val.name] = localStorage.getItem('Rego');
            break;
          case 'a6bf0d4d47602038ef0bbb2fd3ff6f22':
            additional_fields[val.name] = localStorage.getItem('price');
            break;
          case '36820785829f0404de8fb18d886350a7':
            additional_fields[val.name] = allValues.notes;
            break;
          case '73de0266822ac41e149412761cf1b731':
            additional_fields[val.name]=allValues.year
        }
        if (
          Object.keys(additional_fields).length ===
          _.get(props, 'addfield.metadata.items_count')
        ) {
          let addfield = { ...additional_fields, handle_invoice: true };
          createBooking(addfield, clientData);
        }
      });
    }
  };
  /**NEW BOOKING API CALLS */
  const createBooking = (addfield, clientData) => {
    // const pay = _.multiply(total, 100);

    settotal(total);
    const retrieveService = localStorage.hasOwnProperty('service')
      ? localStorage.getItem('service')
      : {};
    const time = localStorage.getItem('time');
    const date = moment(localStorage.getItem('date')).format('YYYY-MM-DD');
    const service = !_.isEmpty(retrieveService)
      ? JSON.parse(retrieveService)
      : {};
    const retreveProduct = localStorage.hasOwnProperty('product')
      ? localStorage.getItem('product')
      : {};
    const product = !_.isEmpty(retreveProduct)
      ? JSON.parse(retreveProduct)
      : {};
    const products = _.map(product, function (val, key) {
      return { id: val.id, qty: 1 };
    });
    let additional_fields = { ...addfield, products: products };
    let requestBody = {
      start_datetime: moment(date + ' ' + time).format('YYYY-MM-DD HH:mm:ss'),
      date,
      time,
      provider_id: localStorage.getItem("selectedProviderId"),
      service_id: service.id,
      products: products,
      client_id: localStorage.getItem('client_id'),
      accept_payment: 'true',
      payment_processor: 'delay',
      additional_fields,
      clientData,
    };
    setLoading(true);
    setBookingData(requestBody);
    props.token(requestBody, value => {
      props.clientBook(requestBody, valu => {
        if (_.get(valu, 'result')) {
          setLoading(false);
          localStorage.setItem('bookId', valu.result.bookings[0].id);
          setOrderId(valu.result.invoice.id);
          localStorage.setItem('order_id', valu.result.invoice.id);
        } else if( _.isEqual(valu.error.message, 'Selected time start is not available')){
          props.setCurrent(1);
          props.setIsSlotAvailable(false);
          localStorage.removeItem('date');
          localStorage.removeItem('time');
          // console.log("Already booked slots");
        } else if (
          _.isEqual(valu.error.message, 'Selected unit id is not available') ||
          _.isEqual(valu.error.message, 'Selected time start is not available')
        ) {
          const ids = _.chain(_.get(props, 'provider.data')).map('id').value();
          var i = 0;
          if (i <= requestBody.provider_id) {
            i++;
            if (i >= requestBody.provider_id) {
              requestBody = { ...requestBody, provider_id: ids[i] };
              props.clientBook(requestBody, valu => {
                if (_.get(valu, 'result')) {
                  setLoading(false);
                  localStorage.setItem('bookId', valu.result.bookings[0].id);
                  setOrderId(valu.result.invoice.id);
                  localStorage.setItem('order_id', valu.result.invoice.id);
                } else {
                  i++;
                  requestBody = { ...requestBody, provider_id: ids[i] };
                  props.clientBook(requestBody, valu => {
                    if (_.get(valu, 'result')) {
                      setLoading(false);
                      localStorage.setItem(
                        'bookId',
                        valu.result.bookings[0].id
                      );
                      setOrderId(valu.result.invoice.id);
                      localStorage.setItem('order_id', valu.result.invoice.id);
                    } else {
                      setLoading(false);
                      notification.error({
                        message: 'Error',
                        description: 'Booking error try another time',
                      });
                    }
                  });
                  // setLoading(false)
                  // notification.error({
                  // 	message: "Error",
                  // 	description: _.capitalize(_.get(valu, "error.message", "Booking error")),
                  // });
                }
              });
            }
          }
        } else {
          setLoading(false);
          notification.error({
            message: 'Error',
            description: _.capitalize(
              _.get(valu, 'error.message', 'Booking error')
            ),
          });
        }
      });
    });
  };

  /**Promo function */
  const promoHandling = () => {
    let requestBody = { code: coupon, id: orderId };
    props.coupons(requestBody, value => {
      notification.success({
        message: 'Success',
        description: 'Applied successfully ',
      });
      localStorage.setItem('couponData', value);
      if (!_.isEmpty(value.promotion_instances)) {
        _.map(value.promotion_instances, function (val, key) {
          settotal(val.amount);
        });
      }
    });
  };

  /**payment call */
  const paymentHandling = token => {
    const retrevevalues = localStorage.hasOwnProperty('allValues')
      ? localStorage.getItem('allValues')
      : {};
    const allValues = !_.isEmpty(retrevevalues)
      ? JSON.parse(retrevevalues)
      : {};
    const clientData = {
      Clientname: allValues.name,
      Clientemail: allValues.email,
      Clientphone: allValues.phone,
    };
    let requestBody = {
      payment_processor: 'stripe',
      id: orderId,
    };
    let urlParams = {
      id: localStorage.getItem('order_id'),
    };
    let params = {
      token,
      amount: _.round(total, 2),
      clientData,
    };
    props.stripeCharge(params, (values, error) => {
      props.payment(
        requestBody,
        urlParams,
        (values, error) => {
          localStorage.clear();
          notification.success({
            message: 'Success',
            description: _.capitalize(_.get(values, 'status')),
          });
        },
        _.get(props, 'stripeCharges') === true
          ? notification.success({
              message: 'error',
              description: 'Payment error',
            })
          : ''
      );

      // let data = { ...bookingData,payment_processor: "stripe",id:localStorage.getItem('bookId') }
      // props.update_booking(data, (value, error) => {
      // 	localStorage.clear();
      // 		notification.success({
      // 			message: "Success",
      // 			description: _.capitalize(_.get(values, "status", "Paid successfully")),
      // 		});
      // })
    });
    if (_.get(props, 'stripeCharges') === true) {
      notification.success({
        message: 'error',
        description: 'Payment error',
      });
    }
  };

  const render = () => {
    const ButtonAfter = <Button onClick={promoHandling}>Apply</Button>;
    const retrieveService = localStorage.hasOwnProperty('service')
      ? localStorage.getItem('service')
      : {};
    const time = localStorage.getItem('time');
    const date = moment(localStorage.getItem('date')).format('YYYY-MM-DD');
    const service = !_.isEmpty(retrieveService)
      ? JSON.parse(retrieveService)
      : {};
    const retrevevalues = localStorage.hasOwnProperty('allValues')
      ? localStorage.getItem('allValues')
      : {};
    const allValues = !_.isEmpty(retrevevalues)
      ? JSON.parse(retrevevalues)
      : {};
    const retreveProduct = localStorage.hasOwnProperty('product')
      ? localStorage.getItem('product')
      : {};
    const product = !_.isEmpty(retreveProduct)
      ? JSON.parse(retreveProduct)
      : {};
    if (_.get(props, 'stripeCharges') === true) {
      return (
        <div className='PaymentSuccessBlock'>
          <Result
            status='error'
            title='Payment error'
            // subTitle="Comprehensive Mobile Inspection Service AUD 280+ Car History Report AUD 22"
          />
          ,
        </div>
      );
    } else if (_.get(props, 'paymentDetails.payment_received')) {
      return (
        <div className='PaymentSuccessBlock'>
          <Result
            status='success'
            title='Successfully Paid'
            // subTitle="Comprehensive Mobile Inspection Service AUD 280+ Car History Report AUD 22"
          />
          ,
        </div>
      );
    } else if (_.isEqual(_.get(props, 'paymentDetails.code'), 400)) {
      props.delete_bookings({ id: localStorage.getItem('bookId') });
      return (
        <div className='PaymentSuccessBlock'>
          <Result
            status='error'
            title={_.get(props, 'paymentDetails.message')}
            // subTitle="Comprehensive Mobile Inspection Service AUD 280+ Car History Report AUD 22"
          />
          ,
        </div>
      );
    } else if (_.get(props, 'clientbooking.result.bookings')) {
      return (
        <div className='PaymentStepsContainer'>
          <div className='StepsFormCardContainer'>
            <div className='row justify-content-center'>
              <div className='col-12 col-md-12 col-lg-6'>
                {(() => {
                  if (_.get(props, 'couponlist.status')) {
                    return (
                      <div style={{ textAlign: 'center' }}>
                        <div className='InspectionStepTitle pb-3'>
                          {_.get(service, 'name')}{' '}
                          <b>$ {_.get(service, 'price')}</b>
                          {_.map(product, function (val, key) {
                            return (
                              <React.Fragment key={key}>
                                {' '}
                                + <br />
                                {_.get(val, 'name')}{' '}
                                <b>$ {_.get(val, 'price')}</b>
                              </React.Fragment>
                            );
                          })}
                        </div>
                        <div className='CouponCodeTotal mb-4'>
                          <div className='row justify-content-center'>
                            <div className='col-auto'>
                              <div className='CouponCodeTotalSection box-shadow-none'>
                                <span className='mr-1'>Total</span>
                                {/* <span className="CouponCodeTotalAmount">$ {total}</span> */}
                                <span className=' mr-1'>
                                  <Text delete>
                                    $ {_.get(props, 'couponlist.deposit')}
                                  </Text>
                                </span>
                                <span className='CouponCodeTotalAmount'>
                                  <Text>
                                    $ {_.get(props, 'couponlist.amount')}
                                  </Text>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })()}
                <div className='StepsFormCard'>
                  <div className='row'>
                    <div className='col-12 col-md-12'>
                      <div className='row'>
                        {(() => {
                          if (_.get(props, 'couponlist.status')) {
                            return (
                              <div className='PricingAddOnTitle'>
                                Coupon Applied
                              </div>
                            );
                          } else {
                            return (
                              <div className='PricingAddOnTitle'>
                                Coupon Code
                              </div>
                            );
                          }
                        })()}
                        {/* <div className="PricingAddOnTitle">
													Coupon Code
												</div> */}
                        <div className='CouponCodeInputSection'>
                          <div className='row justify-content-center'>
                            <div className='col-12 col-md-10 col-lg-10'>
                              <Input
                                placeholder='1245DSERF12'
                                onChange={e => setCoupon(e.target.value)}
                                addonAfter={ButtonAfter}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-12'>
                      <div className='Stripe-container'>
                        <StripeCheckout
                          className='ant-btn PayNowBtn'
                          //   label="Pay Now"
                          image={Logo}
                          description='Secure payments'
                          locale='auto'
                          name='SafeBuy'
                          amount={_.round(total, 2)}
                          currency='AUD'
                          token={token => paymentHandling(token)}
                          stripeKey={GlobalVariable.STRIPE_PUBLISHABLE_KEY}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='PaymentStepsContainer'>
          <div className='StepsFormCardContainer'>
            <div className='row justify-content-center'>
              <div className='col-12 col-md-12 col-lg-6'>
                <div className='StepsFormCard'>
                  <div className='row'>
                    <div className='col-12 col-md-12'>
                      <div className='row'>
                        <div className='form-group col-12 col-lg-6'>
                          <div className='form-label'>Services</div>
                          <div className='form-value'>
                            {_.get(service, 'name')}{' '}
                            <b> $ {_.get(service, 'price')}</b>
                          </div>
                        </div>
                        <div className='form-group col-12 col-lg-6'>
                          <div className='form-label'>Add-ons</div>

                          {_.map(product, function (val, key) {
                            return (
                              <div className='form-value' key={key}>
                                {' '}
                                {_.get(val, 'name')}{' '}
                                <b>$ {_.get(val, 'price')}</b>{' '}
                              </div>
                            );
                          })}
                        </div>
                        <div className='form-group col-12 col-lg-6'>
                          <div className='form-label'>Booking Date</div>
                          <div className='form-value'>{date}</div>
                        </div>
                        <div className='form-group col-12 col-lg-6'>
                          <div className='form-label'>Booking Time</div>
                          <div className='form-value'>{time}</div>
                        </div>
                        <div className='form-group col-12 col-lg-6'>
                          <div className='form-label'>Car Location</div>
                          <div className='form-value'>
                            {localStorage.getItem('selectedValuelocation')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-12'>
                      <div className='steps-action confirm-booking'>
                        <Button
                          type='primary'
                          onClick={bookingClass}
                          loading={loading}
                        >
                          {' '}
                          Confirm Booking
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  return render();
};

const mapStateToProps = state => ({
  addfield: state.booking.additionalfields,
  booking: state.booking.newbookinglist,
  orderlist: state.booking.orderlist,
  paymentDetails: state.booking.paymentDetails,
  couponlist: state.services.couponlist,
  clientbooking: state.clientBooking.clientbookinglist,
  provider: state.services.providerList,
  stripeCharges: state.booking.stripeCharges,
});

const mapDispatchToProps = dispatch => ({
  // create: (params, callback) =>
  // 	dispatch({
  // 		type: NEWBOOKING,
  // 		payload: agent.Bookings.new_booking(params),
  // 		callback: callback,
  // 	}),
  token: (params, callback) =>
    dispatch({
      type: CLIENTTOKEN,
      payload: agent.ClientBooking.token(params),
      callback: callback,
    }),
  clientBook: (params, callback) =>
    dispatch({
      type: CLIENTBOOKING,
      payload: agent.ClientBooking.booking(params),
      callback: callback,
    }),
  order_list: (params, callback) =>
    dispatch({
      type: ORDERLIST,
      payload: agent.Bookings.order_list(params),
      callback: callback,
    }),
  payment: (params, urlParams, callback) =>
    dispatch({
      type: PAYMENTS,
      payload: agent.Bookings.payment(params, urlParams),
      callback: callback,
    }),
  booking_details: (params, callback) =>
    dispatch({
      type: BOOKDETAILS,
      payload: agent.Bookings.bookDetails(params),
      callback: callback,
    }),
  delete_bookings: (params, callback) =>
    dispatch({
      type: DELETEBOOKING,
      payload: agent.Bookings.delete(params),
      callback: callback,
    }),
  coupons: (params, callback) =>
    dispatch({
      type: COUPON,
      payload: agent.Services.promo_code(params),
      callback: callback,
    }),
  stripeCharge: (params, callback) =>
    dispatch({
      type: STRIPECHARGES,
      payload: agent.Bookings.stripe_charges(params),
      callback: callback,
    }),
  update_booking: (params, callback) =>
    dispatch({
      type: UPDATEBOOKING,
      payload: agent.Bookings.update_booking(params),
      callback: callback,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepPayment);

