import React, { useState, useEffect } from "react";
import { Steps, Button } from "antd";
import "./home-page.css";
import StepInspection from "./inspections/step-inspection";
import StepAppointment from "./appointment/step-appointment.js";
import StepSellerDetails from "./car-details/step-seller-details.js";
import StepPayment from "./payment/payment.js";
import _ from "lodash";
import agent from "../../agent";
import {
  NEWBOOKING,
  ORDERLIST,
  PAYMENTS,
  CREATECLIENT,
} from "../../core-units/constants/actionTypes";
import { connect } from "react-redux";
import Locations from "./inspections/location";
import Ripples from "react-ripples";
const { Step } = Steps;

const HomePageComponent = (props) => {
  const [current, setCurrent] = React.useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [check1, setcheck1] = useState(false);
  const [isSlotAvailable, setIsSlotAvailable] = useState(true);
  const [check2, setcheck2] = useState(false);
  const [inspectFlag, setInspectFlag] = useState(false);
  const [appFlag, setAppFlag] = useState(false);
  const [locate, setLocate] = useState(false);
  const [total, settotal] = useState(0);
  const [feildStatus, setFieldStatus] = useState(false);
  const [clientValues, setClientValues] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [rego, setrego] = useState("");
  // let prodId = [];
  const [askingPriceValidation, setAskingPriceValidation] = useState(true); //alobin tech

  const validationHandler = () => {
    setInspectFlag(true);
  };
  const AppvalidationHandler = () => {
    setAppFlag(true);
  };
  const checkValidationHandler = (val) => {
    setcheck2(val);
  };
  const termsValidationHandler = (val) => {
    setcheck1(val);
  };
  const formvalidateHandler = (val) => {
    // localStorage.getItem(allValues)
    setFieldStatus(val);
  };
  const regoValidation = (val) => {
    setrego(val);
  };

  // const askingPriceValidations = (params) => { // alobin tech
  //   let prodId = [];
  //   params.map(val => {
  //     prodId.push(val.id)
  //   })
  //   if (prodId != []) {
  //     if (prodId.includes(4)) {
  //       if (localStorage.getItem("price") == null || "" ||
  //         localStorage.getItem("price") == "null" ||
  //         localStorage.getItem("price") == undefined) {
  //         console.log("step3");
  //         setAskingPriceValidation(false)
  //       } else {
  //         setAskingPriceValidation(true)
  //       }
  //     } else {
  //       setAskingPriceValidation(true)
  //     }
  //   }
  //   console.log("test test", params);
  //   console.log("product Id", prodId);
  //   console.log("asking valid", askingPriceValidation);
  //   console.log("price", localStorage.getItem("price"));
  // }

  const askingPriceValidations = (params) => { // alobin tech
    let prodId = [];

    if (localStorage.getItem('product')) {
      const product = JSON.parse(params)
      if (product != null) {
        product.map(val => {
          prodId.push(val.id)
        })
      }
      // if (prodId != []) {
      if (prodId.includes(4) === true) {
        // console.log("product Id", prodId);
        if (localStorage.getItem("price") === null ||
          localStorage.getItem("price") === "null") {
          setAskingPriceValidation(false)
        }
        else {
          setAskingPriceValidation(true)
        }
      }
    }
    if (prodId.includes(4) === false) {
      // console.log("product Id", prodId);
      setAskingPriceValidation(true)
      localStorage.removeItem('price')
    }

    // console.log("test test", params);
    // console.log("product Id", prodId);
    // console.log("asking valid", askingPriceValidation);
    // console.log("price", localStorage.getItem("price"));
  }

  const steps = [
    {
      content: (
        <StepInspection {...props}
          validationHandler={validationHandler}
          askingPriceValidations={askingPriceValidations}
          setAskingPriceValidation={setAskingPriceValidation}
          askingPriceValidation={askingPriceValidation} />
      ),
    },
    {
      content: (
        <StepAppointment
          {...props}
          AppvalidationHandler={AppvalidationHandler}
          isSlotAvailable={isSlotAvailable}
          setIsSlotAvailable={setIsSlotAvailable}
        />
      ),
    },
    {
      content: (
        <StepSellerDetails
          {...props}
          formvalidateHandler={formvalidateHandler}
          regoValidation={regoValidation}
          checkValidationHandler={checkValidationHandler}
          termsValidationHandler={termsValidationHandler}
        />
      ),
    },
    {
      content: <StepPayment setCurrent={setCurrent} setIsSlotAvailable={setIsSlotAvailable} />,
    },
  ];

  const routingValidation = {      //alobinTech
    route: current !== 0 ? true : false,
    edit: current,
    home: () => setCurrent(0)
  }

  const next = () => {
    setCurrent(current + 1);
  };

  // const nextAndModal = () => {
  //   setCurrent(current + 1);
  //   setIsModalVisible(true);
  // };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    async function apiCall() {
      settotal(localStorage.getItem("total"));
      const retrieveService = localStorage.hasOwnProperty("service")
        ? localStorage.getItem("service")
        : {};
      const retrevevalues = localStorage.hasOwnProperty("allValues")
        ? localStorage.getItem("allValues")
        : {};
      const allValues = !_.isEmpty(retrevevalues)
        ? JSON.parse(retrevevalues)
        : {};
      const time = localStorage.hasOwnProperty("time")
        ? localStorage.getItem("time")
        : "";
      setAppFlag(!_.isEmpty(time) ? true : false);
      setInspectFlag(!_.isEmpty(retrieveService) ? true : false);
      setFieldStatus(
        JSON.parse(localStorage.getItem("feildStatus")) === true ? true : false
      );
      setcheck1(
        JSON.parse(localStorage.getItem("check1")) === true ? true : false
      );
      setcheck2(
        JSON.parse(localStorage.getItem("check2")) === true ? true : false
      );
      const clientData = {
        name: allValues.name,
        email: allValues.email,
        // added parsInt for string to numeric conversion
        phone: allValues.phone,
        //phone: "+61" + allValues.phone.toString(),
      };
      setClientValues(clientData);
    }
    apiCall();
  }, [current]);


  /**Creating client */
  const clientCreation = async () => {
    const retrevevalues = localStorage.hasOwnProperty("allValues")
      ? localStorage.getItem("allValues")
      : {};
    const allValues = !_.isEmpty(retrevevalues)
      ? JSON.parse(retrevevalues)
      : {};
    const clientData = {
      name: allValues.name,
      email: allValues.email,
      // added parsInt for string to numeric conversion
      phone: allValues.phone,
    };
    if (
      clientValues.name !== allValues.name ||
      clientValues.email !== allValues.email ||
      clientValues.phone !== allValues.phone
    ) {
      setClientValues(clientData);
      // props.createClient(clientData, (value) => {
      // 	localStorage.setItem('client_id', value.id);

      // })
    }
  };
  const locValidateHadler = () => {
    setLocate(true);
  };
  const render = () => {
    const retrieveService = localStorage.hasOwnProperty("service")
      ? localStorage.getItem("service")
      : {};
    const service = !_.isEmpty(retrieveService)
      ? JSON.parse(retrieveService)
      : {};
    const location = localStorage.hasOwnProperty("selectedValuelocation")
      ? localStorage.getItem("selectedValuelocation")
      : "";
    return (
      <div className="HomePageContainer">
        <Locations {...props} routingValidation={routingValidation} /> {/* alobinTech*/}
        <div className="StepsSection">
          <div className="container">
            <div className="full-width">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-9">
                  <Steps current={current} className="StepsItems">
                    <Step
                      title="Inspection Options"
                      icon={<i className="sb-checklist" />}
                    />
                    <Step
                      title="Appointment"
                      icon={<i className="sb-calendar1" />}
                    />
                    <Step
                      title="Seller & Car Details"
                      icon={<i className="sb-profile" />}
                    />
                    <Step
                      title="Payment"
                      icon={<i className="sb-mobile-payment" />}
                    />
                  </Steps>
                </div>
              </div>
            </div>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
              {console.log("flag:", inspectFlag)}
              {(() => {
                if (current < steps.length - 1 && current < steps.length - 2) {
                  if (
                    current === 0 &&
                    current < steps.length - 1 &&
                    inspectFlag === true &&
                    localStorage.getItem("selectedValuelocation") &&
                    askingPriceValidation //alobin tech
                  ) {
                    return (
                      <Ripples className="buttonRipple">
                        <Button type="primary" onClick={() => next()}>
                          Next
                        </Button>
                      </Ripples>
                    );
                  } else if (
                    current === 1 &&
                    current < steps.length - 2 &&
                    inspectFlag === true &&
                    appFlag === true
                  ) {
                    return (
                      <Ripples className="buttonRipple">
                        <Button type="primary" onClick={() => next()}>
                          Next
                        </Button>
                      </Ripples>
                    );
                  } else {
                    return (
                      <Ripples className="buttonRipple">
                        <Button type="primary" disabled>
                          Next
                        </Button>
                      </Ripples>
                    );
                  }
                }
              })()}
              {(() => {
                if (current === 2 && current === steps.length - 2) {
                  if (
                    _.isEqual(feildStatus, true) &&
                    check1 === true &&
                    check2 === true &&
                    !_.isEqual(localStorage.getItem("Rego"), "")
                  ) {
                    return (
                      <Button
                        type="primary"
                        onClick={() => {
                          next();
                          clientCreation()
                        }}
                      >
                        Proceed to Payment
                      </Button>
                    );
                  } else {
                    return (
                      <Button type="primary" disabled>
                        Proceed to Payment
                      </Button>
                    );
                  }
                }
              })()}
              {current > 0 &&
                (_.get(props, "paymentDetails.payment_received") ? (
                  ""
                ) : (
                  <Button
                    style={{ margin: "0 8px" }}
                    className="backbutton"
                    onClick={() => prev()}
                  >
                    Back
                  </Button>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => ({
  addfield: state.booking.additionalfields,
  paymentDetails: state.booking.paymentDetails,
});

const mapDispatchToProps = (dispatch) => ({
  createClient: (params, callback) =>
    dispatch({
      type: CREATECLIENT,
      payload: agent.Services.clients(params),
      callback: callback,
    }),
  create: (params, callback) =>
    dispatch({
      type: NEWBOOKING,
      payload: agent.Bookings.new_booking(params),
      callback: callback,
    }),
  order_list: (params, callback) =>
    dispatch({
      type: ORDERLIST,
      payload: agent.Bookings.order_list(params),
      callback: callback,
    }),
  payment: (params, urlParams, callback) =>
    dispatch({
      type: PAYMENTS,
      payload: agent.Bookings.payment(params, urlParams),
      callback: callback,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePageComponent);